@use 'variables' as *;

.recording {
  position: fixed;
  top: 10px;
  left: 96px;
  z-index: 4;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 20px;
  color: $white;
  background-color: $black;

  &-icon {
    color: $label-red;
  }
}

.processing {
  position: fixed;
  top: 10px;
  left: 96px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 20px;
  color: $white;
  font-size: 12px;
}

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: $label-blue;
  border: none;
  border-radius: 50%;

  svg {
    position: relative;
    width: 12px;
    height: 12px;
    color: $white;
  }
}
