@import './variables.scss';

.toast {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  padding: 10px 20px;
  border-radius: 8px;
  z-index: 1000;
  border: 1px solid $border;
  color: $white;

  .icon {
    color: $white;
  }

  &.success {
    background-color: $label-emerald;
  }

  &.error {
    background-color: $label-red;
  }

  &.info {
    background-color: $label-blue;
  }
}
