@use 'variables' as *;

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.panel {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  overflow-y: auto;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 6px;
  transform: translate(-50%, -50%);
}

.text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid $border;
}

.button-container {
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 10px 20px;
  background-color: $main-bg;
}
